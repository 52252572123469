import React from 'react'

import { Router, useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import queryString from 'query-string'

import { isSSR } from '../utils/helpers'

function getApplicantId (query) {
  if (!query) {
    return
  }

  return queryString.parse(query).applicantId
}

const LegacyAdmin = () => {
  const location = useLocation()

  if (!isSSR) {
    const profileId = getApplicantId(location.search) || ''

    if (profileId) {
      navigate(`/profile?id=${profileId}`)
    } else {
      navigate('/')
    }
  }

  return null
}

const LegacyAdminRouter = () => (
  <Router basepath="/admin">
    <LegacyAdmin default />
  </Router>
)

export default LegacyAdminRouter
